import delegate from '../helpers/delegate';
import ps from 'pubsub-js';
import raf from '../helpers/raf';
import scrollTo from '../helpers/scrollTo';
import Swipe from 'swipejs';

class SliderToggle {

    constructor() {
        this.els = {
            slider: document.querySelector('[data-slider-toggle]'),
            expand: document.querySelector('[data-slider-expand]'),
            header: document.querySelector('header[role="banner"]'),
            url: window.location
        };

        if (!this.els.slider) {
            return;
        }

        this.els.slides = [].slice.call(document.querySelectorAll('[data-slider-toggle] > ul > li'));

        this.setSlider();

        delegate(this.els.slider, 'click', '.slider-prev', this.goPrev.bind(this));
        delegate(this.els.slider, 'click', '.slider-next', this.goNext.bind(this));

        if (!this.els.expand) {
            return;
        }

        delegate(document.documentElement, 'click', '[data-slider-expand-button]', this.handleExpand.bind(this));
        delegate(this.els.expand, 'click', '.close', this.handleClose.bind(this));

        delegate(this.els.expand, 'transitionend', (e) => {
            if (e.propertyName !== 'height' || e.target !== this.els.expand) {
                return;
            }

            this.els.expand.classList.remove('toggle');
            this.els.expand.removeAttribute('style');
        });

        window.addEventListener('resize', this.resize.bind(this));

    }


    /**
     * handleClose
     * @param {*} e
     * @description Close slider container
     */
    handleClose(e) {
        e.preventDefault();

        const height = this.els.expand.clientHeight;

        this.els.expand.classList.add('toggle');
        this.els.expand.setAttribute('aria-hidden', 'true');

        this.els.expand.style.overflow = 'hidden';
        this.els.expand.style.height = `${height}px`;

        raf.request(() => {
            this.els.expand.style.height = '0px';

            scrollTo(0, 300);
        });

        this.removeAnchor();
    }


    /**
     * handleExpand
     * @param {*} e
     * @description Expand slider container
     */
    handleExpand(e) {
        e.preventDefault();

        const link = e.currentTarget;
        const id = link.href.split('#')[1];

        this.handleExpandById(id);

    }

    /**
     * open
     */
    open() {
        this.els.expand.classList.add('toggle');
        this.els.expand.setAttribute('aria-hidden', 'false');

        this.refreshSlider();
        const height = this.els.expand.clientHeight;

        this.els.expand.style.height = 0;
        this.els.expand.style.overflow = 'hidden';

        raf.request(() => {
            this.els.expand.style.height = `${height}px`;
        });
    }


    /**
     * setAnchor
     */
    setAnchor() {
        const slideIndex = this.slider.getPos();
        const hash = this.els.slides[slideIndex].getAttribute('id');

        //-- Change hash without triggering event... Instead of 'window.location.hash = hash;'
        history.replaceState(null, null, document.location.pathname + '#' + hash);
    }


    /**
     * removeAnchor
     */
    removeAnchor() {
        history.pushState('', document.title, window.location.pathname + window.location.search);
    }


    /**
     * goNext
     */
    goNext() {
        this.slider.next();
    }


    /**
     * goPrev
     */
    goPrev() {
        this.slider.prev();
    }


    /**
     * refreshSlider
     */
    refreshSlider() {
        this.slider.setup();
    }


    /**
     * resize
     */
    resize() {
        if (!this.slider) {
            return;
        }

        const hiddenAttribute = this.els.expand.getAttribute('aria-hidden');
        const isHidden = hiddenAttribute && hiddenAttribute === 'true';

        if (isHidden) {
            return;
        }

        const index = this.slider.getPos();

        window.clearTimeout(this.resizeTimeout);
        this.resizeTimeout = window.setTimeout(() => {
            this.resizeSlider(index);
        }, 200);
    }


    /**
     * resizeSlider
     * @param {*} index
     * @param {*} onSlide
     */
    resizeSlider(index, onSlide) {
        const height = this.els.slides[index].offsetHeight;

        this.els.slider.style.height = `${height}px`;

        if (onSlide) {
            const top = this.els.slider.getBoundingClientRect().top;
            const bottom = top + height;
            const windowHeight = window.innerHeight;

            if (bottom - windowHeight / 2 < 0) {
                const scrollPos = document.documentElement.scrollTop || document.body.scrollTop;

                scrollTo(scrollPos + bottom - windowHeight / 2, 300);
            }
        }
    }


    /**
     * setSlider
     */
    setSlider() {
        const hash = this.els.url.hash;
        let index0 = 0;
        let foundIndex = false;

        //-- Hash present, find index of slide
        if (hash != '') {
            foundIndex = this.findSlideIndex(hash.split('#')[1]);
            if (foundIndex !== false) {
                index0 = foundIndex;
            }
        }

        this.slider = new Swipe(this.els.slider, {
            startSlide: index0,
            continuous: true,
            draggable: true,
            callback: (index) => {
                this.setAnchor();
                ps.publish('moveTabSlide', index);
                this.resizeSlider(index, true);
            }
        });
        this.els.slider.classList.add('slider-init');

        //-- Hash present, open panel
        if (foundIndex !== false) {
            let slideId = this.els.slides[index0].getAttribute('id');

            this.handleExpandById(slideId);
        }
    }

    /**
     * handleExpandById
     * @param {string} pId
     * @description Expand slider container by id
     */
    handleExpandById(pId) {
        const hiddenAttribute = this.els.expand.getAttribute('aria-hidden');
        const isHidden = hiddenAttribute && hiddenAttribute === 'true';
        const id = pId;
        const target = document.getElementById(id);
        const targetIndex = target.dataset.index;

        if (isHidden) {
            this.open();
            this.slider.slide(targetIndex, 0.01);
            this.resizeSlider(targetIndex);
        }
        else {
            this.slider.slide(targetIndex);
        }

        let offset = this.els.expand.getBoundingClientRect().top + (window.scrollY || window.pageYOffset);

        if (this.els.header) {
            offset -= this.els.header.clientHeight;
        }

        scrollTo(offset, 300);

    }

    /**
     * Returns the index of the element in the slider slides list
     * @param {string} pId DOM id of the slider (hash)
     */
    findSlideIndex(pId) {
        for (const [index, el] of this.els.slides.entries()) {
            if (el.getAttribute('id').toLowerCase() == pId.toLowerCase()) {
                return index;
            }
        };

        return false;
    }
}


export default new SliderToggle();
