function Event( src ){

  // Event object
  if ( src && src.type ) {
    this.originalEvent = src;
    this.type = src.type;

    // Events bubbling up the document may have been marked as prevented
    // by a handler lower down the tree; reflect the correct value.
    this.isDefaultPrevented = src.defaultPrevented ||
        src.defaultPrevented === undefined &&

        // Support: Android<4.0
        src.returnValue === false ? true : false;

  // Event type
  }
  else {
    this.type = src;
  }

  for( var key in src ){
    // cleanup the new event object
    if( key === key.toUpperCase() || 'function' === typeof src[ key ]){
      continue;
    }

    this[ key ] = src[ key ];
  }
}

Event.prototype = {
  constructor: Event,
  isDefaultPrevented: false,
  isPropagationStopped: false,
  isImmediatePropagationStopped: false,

  preventDefault: function() {
    var e = this.originalEvent;

    this.isDefaultPrevented = true;

    if ( e ) {
      e.preventDefault();
    }
  },

  stopPropagation: function() {
    var e = this.originalEvent;

    this.isPropagationStopped = true;

    if ( e ) {
      e.stopPropagation();
    }
  },

  stopImmediatePropagation: function() {
    var e = this.originalEvent;

    this.isImmediatePropagationStopped = true;

    if ( e ) {
      e.stopImmediatePropagation();
    }

    this.stopPropagation();
  }
};

function delegate( root, event, selector, callback, options ){

  var delegate;

  if( 'string' === typeof selector ){
    delegate = true;
  }
  else{
    options = callback;
    callback = selector;
  }

  if( callback.handleEvent ){
    callback = callback.handleEvent.bind( callback );
  }

  function fixEvent( event, extend ){
    // Create a writable copy of the event object
    const originalEvent = event;

    event = new Event( originalEvent );

    // Support: Safari 6-8+
    // Target should not be a text node (#504, #13143)
    if ( event.target.nodeType === 3 ) {
      event.target = event.target.parentNode;
    }

    for( var key in extend ){
      event[ key ] = extend[ key ];
    }

    return event;
  }

  // unbind
  function off(){
    root.removeEventListener( event, testMatch, options );
  }

  // event handler
  function testMatch( e ){

    // if theres no delegation send the event directly
    if( !delegate ){
      callback( fixEvent( e, { currentTarget: e.currentTarget }));

      if( options && options.once ){
        off();
      }

      return;
    }

    // find the closest match for the passed selector
    var match = e.target.closest( selector );

    if( !match ){
      return;
    }

    // fire the callback
    callback( fixEvent( e, { currentTarget: match }));

    if( options && options.once ){
      off();
    }

  }

  // bind
  root.addEventListener( event, testMatch, options );

  // return passed parameters and the unbind method
  return {
    event: event,
    options: options,
    selector: selector,
    unbind: off
  };
}

module.exports = delegate;
