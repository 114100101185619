
const config = window.config.forms;

function getMessage( input, detail ){
  let messages;
  let text;

  try {
    messages = config.errors;
    messages = messages[ detail.validityState ] || messages;
  }
  catch ( e ) {
    messages = null;
  }

  const type = input.dataset.type || input.type;

  // Get error message
  if ( !messages ) {
    text = detail.validityState;
  }
  else if ( messages[ type ]) {
    text = messages[ type ];
  }
  else {
    text = messages.base;
  }

  return text;
}

function errorMessage( input, message ) {
  if( !input ) {
    return;
  }

  const parent = input.closest( '.form-field' ) || input.parentNode;
  let errorMessage = parent.querySelector( '.form-error-msg' );

  // Create error message if not there
  if( !errorMessage ){
    errorMessage = document.createElement( 'p' );
    errorMessage.innerHTML = message;
    errorMessage.classList.add( 'form-error-msg' );
    parent.appendChild( errorMessage );
  }
  else {
    errorMessage.innerHTML = message;
  }

  const id = input.id;

  // Create aria-describedby attribute and store current attribute
  if( id ){
    const errorId = `error-${id}`;
    let ariaDescribedby = input.getAttribute( 'aria-describedby' );

    if( ariaDescribedby ){
      const attributes = ariaDescribedby.split( ' ' ).filter( attr => attr.lastIndexOf( 'error-', 0 ) !== 0 );

      if( attributes.length ){
        input.dataset.ariaDescribedby = attributes.join( ' ' );
      }

      attributes.push( errorId );
      ariaDescribedby = attributes.join( ' ' );
    }
    else {
      ariaDescribedby = errorId;
    }

    errorMessage.id = errorId;
    input.setAttribute( 'aria-describedby', ariaDescribedby );
  }
}

function removeErrorMessage( input ){
  const field = input.closest( '.form-field' );

  input.removeAttribute( 'aria-invalid' );

  const ariaDescribedby = input.dataset.ariaDescribedby;

  if( ariaDescribedby ){
    input.setAttribute( 'aria-describedby', ariaDescribedby );
  }
  else {
    let attributes = input.getAttribute( 'aria-describedby' );

    if( attributes ){
      attributes = attributes.split( ' ' ).filter( attr => attr.lastIndexOf( 'error-', 0 ) !== 0 );
      if( attributes.length ){
        input.setAttribute( 'aria-describedby', attributes.join( ' ' ));
      }
      else {
        input.removeAttribute( 'aria-describedby' );
      }
    }
  }

  if( field ){
    field.classList.remove( 'form-error' );
  }

  const parent = field || input.parentNode;
  const errorMessage = parent.querySelector( '.form-error-msg' );

  if( errorMessage ){
    errorMessage.parentNode.removeChild( errorMessage );
  }
}

function setErrorMessage( input, message ){
  if( !input ){
    return;
  }

  const field = input.closest( '.form-field' );

  input.setAttribute( 'aria-invalid', true );

  if( field ) {
    field.classList.add( 'form-error' );
  }

  if( input.type === 'radio' ){
    const name = input.name;
    const inputs = [].slice.call( input.closest( 'form' ).elements[ name ]);

    if( inputs.indexOf( input ) < inputs.length - 1 ){
      return;
    }
  }

  // Write error message
  errorMessage( input, message );
}

export default { getMessage, setErrorMessage, removeErrorMessage };
