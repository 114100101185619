window.config = {
    "forms": {
        "errors": {
            "base": "Merci de corriger cette information",
            "valueMissing": {
                "base": "Merci de renseigner cette information"
            },
            "badInput": {
                "base": "Merci de corriger cette information"
            },
            "typeMismatch": {
                "base": "Merci de corriger cette information"
            }
        }
    }
};