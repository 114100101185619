/**
 * Methods to disable or enable interactive elements from a context element
 */

const focusables = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex], [contentEditable]';

/**
 * disable the focusable elements located in the context
 * @param {HTMLElement} context - the element where to look for focusable elements
 */
function set( context = document.body, activeElement ){
  // if context already has disabled elements dont set inert
  if( context.disabledElements ){
    return;
  }

  const disabledElements = [];

  // loop over all focusable element in the dom to set their "tabindex" to -1 if >= 0
  Array.prototype.forEach.call( context.querySelectorAll( focusables ), el => {
    // skip focusable element contained in the activeElement
    if( activeElement && activeElement.contains( el ) || el.closest( '[hidden]' )){
      return;
    }

    const tabindex = parseInt( el.getAttribute( 'tabindex' ), 10 );

    if( tabindex ){
      // don't change the tabindex if it is < 0
      if( tabindex < 0 ){
        return;
      }

      // store previous setted tabindex
      el.defaultTabindex = tabindex;
    }

    // store the focusable element so we can loop on it later
    disabledElements.push( el );

    // "disable" the element
    el.setAttribute( 'tabindex', '-1' );
  });

  // store "disabled" elements to loop on it later
  context.disabledElements = disabledElements.length ? disabledElements : null;
  context.setAttribute( 'aria-hidden', 'true' );
}

function unset( context = document.body ){
  const disabledElements = context.disabledElements || [].slice.call( context.querySelectorAll( focusables ));

  disabledElements.forEach( el => {
    const defaultTabindex = el.defaultTabindex;

    if( !defaultTabindex ){
      el.removeAttribute( 'tabindex' );

      return;
    }

    // set the tabindex to its previous value
    el.setAttribute( 'tabindex', defaultTabindex );
    el.defaultTabindex = null;
  });

  context.disabledElements = null;
  context.setAttribute( 'aria-hidden', 'false' );
}

export default { set, unset, focusables };
