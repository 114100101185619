import delegate from '../helpers/delegate';
import raf from '../helpers/raf';
import sidePadding from '../helpers/side-padding';

class Navigation{
  constructor(){
    this.el = document.getElementById( 'menu' );

    if( !this.el ){
      return;
    }

    this.els = {
      buttons: Array.from( document.querySelectorAll( `[aria-controls="${this.el.id}"]` )),
      close: this.el.querySelector( '.menu-close' )
    };

    document.body.addEventListener( 'keydown', this );
    this.el.addEventListener( 'transitionend', this );
    this.el.addEventListener( 'click', this );

    delegate( document.body, 'click', '[aria-controls="menu"]', this );
  }

  _handleClick( event ){
    const button = event.target.closest( 'button' );

    if( !button ){
      if( this.el === event.target ){
        this.toggle( true );
      }

      return;
    }

    if( button.classList.contains( '.menu-close' )){
      this.toggle( true );

      return;
    }


    this.toggle();
  }

  handleEvent( event ){
    switch( event.type ){
      case 'click':
        this._handleClick( event );
        break;
      case 'transitionend':
        this._handleTransition( event );
        break;
      case 'keydown':
        this._handleKey( event );
        break;
    }
  }

  _handleKey( event ){
    if( event.keyCode !== 27 || !this.el.contains( event.target )){
      return;
    }

    this.toggle( true );
  }

  _handleTransition( event ){
    if( !event.target.classList.contains( 'content' )){
      return;
    }

    this.el.classList.remove( 'move' );

    if( !this.opened ){
      raf.request(() => {
        sidePadding.unset();

        this.els.buttons[ 0 ].focus();
      });
    }
    else {
      this.els.close.focus();
    }
  }

  toggle( state = this.el.getAttribute( 'aria-hidden' ) !== 'true' ){
    this.opened = !state;

    this.el.classList.add( 'move' );

    this.el.setAttribute( 'aria-hidden', state );

    this.els.buttons.forEach( button => {
      button.setAttribute( 'aria-expanded', !state );
    });

    if( !state ){
      sidePadding.set();
    }

    raf.request(() => {
      document.documentElement.classList.toggle( 'nav', !state );
    });
  }

}

export default new Navigation();
