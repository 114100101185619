import delegate from '../helpers/delegate';

/**
 * Selects
 * @constructor
 * @requires pubsub-js
 */
class CustomSelects {
  constructor() {
    this.els = {};

    this.dom();
    this.bind();

    if ( this.els.selects.length ) {
      this.load();
    }
  }


  /**
   * Declare all event binding
   * @method
  **/
  bind() {
    delegate( document.body, 'change', '.select select', this.change.bind( this ));
  }


  /**
   * Update custom select label
   * @method
  **/
  change( e, refresh ) {
    if ( e.target.nodeName !== 'SELECT' ) {
      return;
    }

    const select = e.target;
    const parent = select.parentNode;
    const label = parent.querySelector( '.select-label' );
    const option = select.options[ select.selectedIndex ];
    const val = option.getAttribute( 'data-label' ) || option.innerHTML;
    const isUrl = option.hasAttribute( 'data-link' );
    const isAnchor = option.hasAttribute( 'data-anchor' );

    label.innerHTML = val;

    if( refresh ) {
      return;
    }

    if( isUrl ) {
      window.location = option.value;
    }

    if( isAnchor ) {
      const id = option.value.split( '#' )[ 1 ];

      if( !id ) {
        return;
      }

      const target = document.getElementById( id );

      window.scrollTo( 0, target.getBoundingClientRect().top + ( window.scrollY || window.pageYOffset ));
    }
  }


  /**
   * Store DOM elements
   * @method
  **/
  dom() {
    this.els.selects = [].slice.call( document.querySelectorAll( '.select select' ));
  }


  /**
   * Handle initial load
   * @method
  **/
  load() {
    this.els.selects.forEach( select => {
      this.change({
        target: select
      }, true );
    });
  }
}

export default new CustomSelects;
