class TooltipRIO {
  /**
   * @constructor
   */
  constructor() {
    this.el = document.querySelector( '[data-requires-rio]' );
    this.tooltip = document.querySelector( '.toolTip' );
    this.listener();
    this.thisRemoveDocListener = this.removeDocListener.bind( this );
    this.isListening = false;
  }

  /**
   * listener on click
   */
  listener() {
    if ( this.el ) {
      this.el.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        e.stopPropagation();

        this.tooltip.classList.toggle( 'isActive' );

        if( this.isListening ) {
          this.thisRemoveDocListener();
        }
        else {
          this.isListening = true;
          document.addEventListener( 'click', this.thisRemoveDocListener, false );
        }
      });
    }
  }

  removeDocListener() {
    // console.log( this, this.isListening );
    this.isListening = false;
    this.tooltip.classList.remove( 'isActive' );
    document.removeEventListener( 'click', this.thisRemoveDocListener, false );
  }
}

export default new TooltipRIO();
