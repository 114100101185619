import delegate from '../helpers/delegate';
import Validator from '@switch-company/form-validation';
import utils from '../helpers/form-utils';

const FIELDS_SELECTOR = 'input, textarea, select';

/**
 * Module
 * @constructor
 */
class formValidation {
  constructor( form ){
    this.els = {
      form,
      fields: [].slice.call( form.querySelectorAll( FIELDS_SELECTOR ))
    };
    this.validator = new Validator( this.els.form );

    this.bind();
  }

  bind(){
    this.els.form.addEventListener( 'invalid', this.inputStatus.bind( this ));
    this.els.form.addEventListener( 'valid', this.inputStatus.bind( this ));
    this.els.form.addEventListener( 'submit', this.check.bind( this ));

    delegate( this.els.form, 'change', '[data-form-toggle]', this.handleChange.bind( this ));
  }

  check( e ){
    const isEvent = e.target;

    if( !this.validator.checkValidity()) {
      if( isEvent ) {
        e.preventDefault();
      }

      this.focusFirstError();
    }
  }

  focusFirstError(){
    // Focus on first error
    const first = this.els.form.querySelector( '[aria-invalid]' );

    if( !first ) {
      return;
    }

    first.focus();
  }

  handleChange( e ){
    const input = e.target;

    if( this.validator.checkValidity( input )){
      if( input.type === 'radio' ){
        const name = input.name;
        const inputs = [].slice.call( this.els.form.elements[ name ]);

        inputs.forEach( field => {
          utils.removeErrorMessage( field );
        });
      }
      else {
        utils.removeErrorMessage( input );
      }
    }
  }

  inputStatus( e ){
    const input = e.target;

    if( input.type === 'hidden' ) {
      return;
    }

    if( e.type === 'invalid' ) {
      const message = utils.getMessage( input, e.detail );

      utils.setErrorMessage( input, message );
    }
    else {
      utils.removeErrorMessage( input );
    }
  }
}

export default formValidation;
