import delegate from '../helpers/delegate';
import ps from 'pubsub-js';
import Swipe from 'swipejs';

class TabSlider{
  constructor(){
    this.els = {};
    this.mq = window.matchMedia( `(min-width: ${1000 / 16}em)` );

    this.dom();

    if( !this.els.slider ){
      return;
    }

    this.mq.addListener( this.resize.bind( this ));

    if( !this.mq.matches ){
      this.set();
    }

    delegate( document.documentElement, 'click', '[data-tab-slider-nav]', this.handleTab.bind( this ));

    ps.subscribe( 'moveTabSlide', this.moveTabSlide.bind( this ));

  }

  dom() {
    this.els.slider = document.querySelector( '[data-tab-slider]' );
    this.els.tabs = [].slice.call( document.querySelectorAll( '[data-tab-slider-nav]' ));
  }

  handleTab( e ){
    if( !this.slider ){
      return;
    }

    const button = e.currentTarget;
    const id = button.dataset.tabSliderNav;
    const slide = document.getElementById( id );

    this.slider.slide( slide.dataset[ 'index' ]);
  }

  moveTabSlide( e, index ){
    if( !this.slider ) {
      return;
    }

    this.slider.slide( index );
  }

  resize() {
    if( this.mq.matches ){
      this.unset();
    }
    else {
      this.set();
    }
  }

  set() {
    let startIndex = 0;

    if( this.els.tabs.length ){
      let startCurrent = document.querySelector( '[data-tab-slider-nav][aria-current]' );

      if( !startCurrent ){
        startCurrent = document.querySelector( '[data-tab-slider-nav]' );
        startCurrent.setAttribute( 'aria-current', 'true' );
      }
      else {
        startIndex = this.els.tabs.indexOf( startCurrent );
      }
    }

    this.slider = new Swipe( this.els.slider, {
      startSlide: startIndex,
      continuous: false,
      draggable: false,
      callback: ( index, elem ) => {
        const id = elem.id;
        const current = document.querySelector( '[data-tab-slider-nav][aria-current]' );
        const tab = document.querySelector( `[data-tab-slider-nav="${id}"]` );

        if ( current ){
          current.removeAttribute( 'aria-current' );
        }
        if( tab ){
          tab.setAttribute( 'aria-current', 'true' );
        }
      }
    });
    this.els.slider.classList.add( 'slider-init' );
  }

  unset() {
    if( !this.slider ) {
      return;
    }

    this.slider.kill();
    this.slider = null;
    this.els.slider.classList.remove( 'slider-init' );
  }

}

export default new TabSlider();
