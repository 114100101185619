import validation from './form-validation';

/**
 * Module
 * @constructor
 */
class forms {

  constructor(){
    this.setup();
  }

  setup( context = document ){
    const forms = [].slice.call( context.querySelectorAll( 'form[novalidate]' ));

    forms.forEach( form => {
      new validation( form );
    });

  }
}

export default new forms;
