import delegate from '../helpers/delegate';

/**
 * Module
 * @constructor
 */
class labelHolder{

  constructor(){
    this.bind();

    window.requestAnimationFrame( this.refreshLabelHolders.bind( this ));
  }


  bind(){
    delegate( document.body, 'input', '.label-holder', this.handleInput.bind( this ));
  }

  handleInput( e ){
    const filled = e.target.value.trim().length > 0;

    e.currentTarget.classList.toggle( 'filled', filled );
  }

  refreshLabelHolders(){
    let autofillInputs;

    try{
      autofillInputs = document.querySelectorAll( ':input:-webkit-autofill' );
    }
    catch( e ){
      // e
    }

    if( autofillInputs ){
      Array.prototype.forEach.call( autofillInputs, input => {
        this.handleInput({
          currentTarget: input.parentElement,
          target: input
        });
      });
    }
  }
}

export default new labelHolder;
