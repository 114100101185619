import delegate from '../helpers/delegate';
import fetcher from '@switch-company/fetcher';
import formUtils from '@switch-company/form-utils';

class CartRefresh{
  constructor( el ){
    this.el = el;
    this.fetchURL = this.el.dataset.cartRefresh;

    this.els = {
      recap: document.body.querySelector( '.content-aside .recap' )
    };

    this.refreshCart = this.refreshCart.bind( this );

    delegate( this.el, 'change', '[data-refresh]', this.refreshCart );
  }

  refreshCart( event ){
    const input = event.target;
    const parentBlock = input.closest( '.box' );
    let statusEl;

    if( parentBlock ){
      statusEl = parentBlock.querySelector( '.price' );
    }

    fetcher.send( this.fetchURL, {
      method: 'post',
      data: formUtils.toJSON( input.form )
    })
      .then( response => {
        this.updateCart( response.data, statusEl );
      })
      .catch( e => console.error( e.data, e ));
  }

  updateCart( data, statusEl ){
    if( data.recap ){
      this.els.recap.innerHTML = data.recap;
    }

    if( statusEl && data.status ){
      statusEl.innerHTML = data.status;
    }
  }
}

export default ( function(){
  const formCart = document.querySelector( 'form[data-cart-refresh]' );

  if( !formCart ){
    return false;
  }

  return new CartRefresh( formCart );
})();
