import delegate from '../helpers/delegate';
import raf from '../helpers/raf';
import utils from '../helpers/form-utils';
import expandtoggle from '../modules/expand-toggle';

const INPUTS_SELECTOR = 'input, textarea, select';

class Header{
  constructor(){
    this.els = {
      blocks: [].slice.call( document.querySelectorAll( '[data-toggle][aria-hidden]' ))
    };

    this.toggleOptions = this.toggleOptions.bind( this );
    delegate( document.documentElement, 'expandtoggle', '[data-toggle][aria-hidden]', this.toggleOptions );

    if( this.els.blocks.length ){
      this.els.blocks.forEach( block => {
        const attr = block.getAttribute( 'aria-hidden' );
        const hidden = !!attr && attr === 'true';

        if( hidden ){
          this.disable( block );
        }
      });
    }
  }

  disable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inputs.forEach( input => {
      input.disabled = true;
    });

    // Clean error messages
    const errorFieds = [].slice.call( target.querySelectorAll( '[aria-invalid]' ));

    errorFieds.forEach( input => {
      utils.removeErrorMessage( input );
    });
  }

  enable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inputs.forEach( input => {
      if( !input.closest( '.hidden' )){
        input.disabled = false;
      }
    });
  }


  toggleOptions( event ){
    const block = event.currentTarget;
    const { next, hide } = event.detail;

    const height = block.clientHeight;

    delegate( block, 'transitionend', () => {
      block.removeAttribute( 'style' );
      next( block, hide );

      if( this.expandCloseCallback ){
        this.expandCloseCallback();
      }

      if( hide ) {
        this.disable( block );
      }
    }, { once: true });

    if( hide ){
      block.style.overflow = 'hidden';
      block.style.height = `${height}px`;

      raf.request(() => {
        block.style.height = '0px';
      });

      return;
    }

    this.enable( block );

    block.style.height = 0;
    block.style.overflow = 'hidden';

    const attr = block.dataset.toggle;
    const isLayer = attr && attr === 'layer';

    if( expandtoggle.layers.length && isLayer ){
      this.expandCloseCallback = function(){
        this.expandCloseCallback = null;

        raf.request(() => {
          block.style.height = `${height}px`;
        });
      };

      expandtoggle.closeLayers( block );

      return;
    }

    raf.request(() => {
      block.style.height = `${height}px`;
    });
  }
}

export default new Header();
