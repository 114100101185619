import delegate from '../helpers/delegate';
import inert from '../helpers/inert';
import utils from '../helpers/form-utils';
import scrollTo from '../helpers/scrollTo';


const MODULE_SELECTOR = '[data-form-toggle]';
const INPUTS_SELECTOR = 'input, textarea, select';

/**
 * Module
 * @constructor
 */
class formToggle {
  constructor(){
    this.els = {
      header: document.querySelector( 'header[role="banner"]' )
    };

    this.bind();
    this.defaultState();
  }

  bind(){
    delegate( document.documentElement, 'change', MODULE_SELECTOR, this.handleToggle.bind( this ));
  }

  /**
   * trigger event on page load to display the correct state
   */
  defaultState() {
    Array.prototype.forEach.call( document.querySelectorAll( MODULE_SELECTOR ), el => {
      this.handleToggle( el, true );
    });
  }

  disable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inert.set( target );

    inputs.forEach( input => {
      input.disabled = true;
    });

    // Clean error messages
    const errorFieds = [].slice.call( target.querySelectorAll( '[aria-invalid]' ));

    errorFieds.forEach( input => {
      utils.removeErrorMessage( input );
    });
  }

  disableSiblings( input ){
    Array.prototype.forEach.call( input.form[ input.name ], radio => {
      if( radio === input ){
        return;
      }

      const target = document.getElementById( radio.dataset.formToggle );

      if( !target ){
        return;
      }
      this.toggleDisplay( target, false );
    });
  }

  enable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inputs.forEach( input => {
      if( !input.closest( '.hidden' )){
        input.disabled = false;
      }
    });

    inert.unset( target );
  }

  handleToggle( e, init ){
    const el = e.currentTarget || e;
    const id = el.dataset.formToggle;
    const target = document.getElementById( id );
    const revert = el.hasAttribute( 'data-form-toggle-revert' );

    if( el.type === 'radio' && !init ){
      this.disableSiblings( el );

      const name = el.name;
      const firstRadio = el.form.querySelector( `[name="${name}"]` );
      let offset = firstRadio.getBoundingClientRect().top + ( window.scrollY || window.pageYOffset ) - 10;

      if( this.els.header ){
        offset -= this.els.header.clientHeight;
      }

      scrollTo( offset, 300 );
    }

    if( !target ){
      return;
    }

    let state = el.checked;

    if( e.type === 'click' ){
      state = el.getAttribute( 'aria-checked' ) !== 'true';
      el.setAttribute( 'aria-checked', state );
    }

    if( revert ){
      state = !state;
    }

    this.toggleDisplay( target, state );
  }

  handleTransition( e ){
    if( e.propertyName !== 'height' ){
      return;
    }

    const target = e.currentTarget;
    const isVisible = target.visibilityState;

    target.removeAttribute( 'style' );

    if( !isVisible ){
      target.classList.add( 'hidden' );
      this.disable( target );
    }
  }

  toggleDisplay( target, show ){
    target.visibilityState = show;

    delegate( target, 'transitionend', this.handleTransition.bind( this ), { once: true });

    target.style.display = 'block';
    target.style.height = 'auto';

    const openSize = target.clientHeight;

    if( target.classList.contains( 'no-transition' )){
      window.setTimeout(() => {
        target.classList.remove( 'no-transition' );
      }, 20 );
    }

    if( show ){
      target.classList.remove( 'hidden' );
      this.enable( target );

      target.style.height = '0rem';

      window.requestAnimationFrame(() => {
        target.style.height = `${openSize / 16}rem`;
      });
    }
    else {
      target.removeAttribute( 'style' );

      if( target.classList.contains( 'hidden' )){
        target.classList.add( 'hidden' );
        this.disable( target );

        return;
      }
      target.style.height = `${openSize / 16}rem`;

      window.setTimeout(() => {
        target.style.height = '0rem';
      }, 100 );
    }
  }
}

export default new formToggle;
