import delegate from '../helpers/delegate';

const MODULE_SELECTOR = '[data-button-layer]';

class ButtonLayer{
  constructor(){

    delegate( document.body, 'click', `${MODULE_SELECTOR} [aria-controls]`, this._handleToggle.bind( this ));
    document.documentElement.addEventListener( 'click', this._handleClose.bind( this ));
    document.documentElement.addEventListener( 'keydown', this._handleKey.bind( this ));
  }

  _handleClose( e ) {
    const openedElements = [].slice.call( document.querySelectorAll( `${MODULE_SELECTOR} [aria-expanded="true"]` ));
    const clickedElement = e.target;

    if( !openedElements || !openedElements.length ){
      return;
    }

    openedElements.forEach( elt => {
      const block = elt.closest( MODULE_SELECTOR );

      if( block.contains( clickedElement )){
        return;
      }

      this._handleToggle( elt );

    });
  }

  _handleKey( e ){
    if( e.keyCode !== 27 ){
      return;
    }

    const openedElements = [].slice.call( document.querySelectorAll( `${MODULE_SELECTOR} [aria-expanded="true"]` ));

    if( !openedElements || !openedElements.length ){
      return;
    }

    openedElements.forEach( elt => {
      this._handleToggle( elt );

    });
  }

  _handleToggle( e ) {
    const button = e.currentTarget || e;
    const layer = document.getElementById( button.getAttribute( 'aria-controls' ));


    if( !layer ) {
      return;
    }
    const hide = layer.getAttribute( 'aria-hidden' ) === 'false';

    layer.setAttribute( 'aria-hidden', hide );
    button.setAttribute( 'aria-expanded', !hide );
  }
}

export default new ButtonLayer();
