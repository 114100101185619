import delegate from '../helpers/delegate';
import inert from '../helpers/inert';
import utils from '../helpers/form-utils';
import raf from '../helpers/raf';

const INPUTS_SELECTOR = 'input, textarea, select';

class SelectionExpand{
  constructor(){
    this.els = {
      blocks: [].slice.call( document.querySelectorAll( '[data-selection-expand-target]' ))
    };

    delegate( document.documentElement, 'click', '[data-selection-expand]:not(select)', this.handleExpand.bind( this ));
    delegate( document.documentElement, 'change', 'select[data-selection-expand]', this.handleExpand.bind( this ));

    if( this.els.blocks.length ){
      this.els.blocks.forEach( block => {
        const attr = block.getAttribute( 'aria-hidden' );
        const hidden = !!attr && attr === 'true';

        if( hidden ){
          this.disable( block );
        }
      });
    }
  }

  disable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inert.set( target );

    inputs.forEach( input => {
      input.disabled = true;
    });

    // Clean error messages
    const errorFieds = [].slice.call( target.querySelectorAll( '[aria-invalid]' ));

    errorFieds.forEach( input => {
      utils.removeErrorMessage( input );
    });
  }

  enable( target ){
    const inputs = [].slice.call( target.querySelectorAll( INPUTS_SELECTOR ));

    inputs.forEach( input => {
      if( !input.closest( '.hidden' )){
        input.disabled = false;
      }
    });

    inert.unset( target );
  }

  fillForm( target, values ) {
    const form = target.closest( 'form' );

    if( !form ) {
      return;
    }

    for ( let key in values ){
      const field = form.elements[ key ];

      if( field ){
        field.value = values[ key ];
      }
    }
  }

  handleExpand( e ) {
    let values;
    const el = e.currentTarget;
    const id = el.dataset.selectionExpand;
    const target = document.querySelector( `[data-selection-expand-target="${id}"]` );

    if( el.nodeName.toLowerCase() !== 'select' ){
      values = el.dataset.values;

      if( values ) {
        this.fillForm( target, JSON.parse( values ));
      }

      this.show( target );

      return;
    }

    const option = el.options[ el.selectedIndex ];

    values = option.dataset.values;

    if( values ){
      this.fillForm( target, JSON.parse( values ));
      this.show( target );
    }
    else {
      this.hide( target );
    }
  }

  hide( target ){
    const attr = target.getAttribute( 'aria-hidden' );
    const hidden = !!attr && attr === 'true';

    if( hidden ) {
      return;
    }

    this.toggle( target, true );
  }

  toggle( block, hide ){
    block.classList.add( 'toggle' );

    const height = block.clientHeight;

    delegate( block, 'transitionend', () => {
      block.removeAttribute( 'style' );
      block.classList.remove( 'toggle' );

      if( hide ){
        this.disable( block );
      }
    }, { once: true });

    if( hide ){
      block.style.overflow = 'hidden';
      block.style.height = `${height}px`;

      raf.request(() => {
        block.style.height = '0px';
      });

      return;
    }

    block.style.height = 0;
    block.style.overflow = 'hidden';

    raf.request(() => {
      this.enable( block );
      block.style.height = `${height}px`;
    });
  }

  show( target ){
    const attr = target.getAttribute( 'aria-hidden' );
    const hidden = !!attr && attr === 'true';

    if( !hidden ) {
      return;
    }

    this.toggle( target, false );
  }
}

export default new SelectionExpand();
