// polyfils
import './polyfills/classList.js';
import './polyfills/closest.js';
import './polyfills/customEvent.js';
import './polyfills/array-from.js';
import './polyfills/object-assign.js';

import 'formdata-polyfill';
import 'es6-promise/auto';
import 'whatwg-fetch';

// Tools
import ps from 'pubsub-js';
import KB from '@switch-company/keyboard-navigation';

// Helpers
import sidePadding from './helpers/side-padding';

new KB();

sidePadding.update();

// Libs
import './modules/config';
import './modules/custom-select';
import './modules/forms';
import './modules/form-toggle';
import './modules/label-holder';
import './modules/menu';
import './modules/expand-toggle';
import './modules/expand-slide';
import './modules/expand-selection';
import './modules/tab-slider';
import './modules/slider-toggle';
import './modules/button-layer';
import './modules/cartparge-refresh';
import './modules/tooltip-rio';
import './modules/bubble-info';

ps.publish( 'app.start' );
