import delegate from '../helpers/delegate';

const focusables = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, [tabindex], [contentEditable]';

const CLOSE_SELECTOR = '.expand-close';
const TOGGLE_SELECTOR = '.expand-toggle[aria-controls]';


class ExpandToggle{
  constructor(){
    this._handleToggle = this._handleToggle.bind( this );
    this._handleClose = this._handleClose.bind( this );

    this._expands = [];
    this._layers = [];

    delegate( document.body, 'click', CLOSE_SELECTOR, this._handleClose.bind( this ));
    delegate( document.body, 'click', TOGGLE_SELECTOR, this._handleToggle.bind( this ));
  }

  get blocks(){
    return this._expands.slice();
  }

  get layers(){
    return this._layers.slice();
  }

  _clean( block, hide ){
    block.classList.remove( 'toggle' );

    if( hide ){
      if( block.contains( document.activeElement )){
        block.activeOpener.focus();
      }

      return;
    }

    let interactiveElement;
    const screenHeight = window.innerHeight;

    // search for every focusable element in the layer
    Array.prototype.some.call( block.querySelectorAll( focusables ), el => {
      const height = el.getBoundingClientRect().bottom;
      const bottom = el.offsetTop + height;

      // test if focusable element is visible, not below the fold
      if( bottom <= screenHeight && ( el.offsetWidth || el.offsetHeight )){
        interactiveElement = el;

        return true;
      }

      return false;
    });

    if( !interactiveElement ){
      return;
    }

    interactiveElement.focus();
  }

  _handleClose( event ){
    const button = event.target.closest( CLOSE_SELECTOR );

    if( !button ){
      return;
    }

    const block = button.closest( '[aria-hidden]' );

    if( !block ){
      return;
    }

    this.toggle( block, true );
  }

  _handleToggle( event ){
    const button = event.target.closest( TOGGLE_SELECTOR );

    if( !button ){
      return;
    }

    const block = document.getElementById( button.getAttribute( 'aria-controls' ));

    if( !block ){
      return;
    }

    block.activeOpener = button;

    this.toggle( block );
  }

  closeAll( keep ){
    this._expands.forEach( expand => {
      if( expand === keep ){
        return;
      }

      this.toggle( expand, true );
    });
  }

  closeLayers( keep ){
    this._layers.forEach( expand => {
      if( expand === keep ){
        return;
      }

      const attr = expand.dataset.toggle;

      if( attr && attr === 'layer' ){
        this.toggle( expand, true );
      }
    });
  }

  toggle( block, hide ){
    hide = hide !== undefined ? hide : block.getAttribute( 'aria-hidden' ) !== 'true';

    if( !block.openers ){
      block.openers = Array.from( document.querySelectorAll( `[aria-controls="${block.id}"]` ));
    }

    block.classList.add( 'toggle' );

    block.openers.forEach( opener => {
      opener.setAttribute( 'aria-expanded', !hide );
    });

    block.setAttribute( 'aria-hidden', hide );

    this.trigger( block, hide );
    this._updateStore( block, hide );
  }

  trigger( block, hide ){
    const params = {
      bubbles: true,
      cancelable: false,
      detail: {
        hide,
        next: () => {
          this._clean( block, hide );
        }
      }
    };

    const event = new CustomEvent( 'expandtoggle', params );

    block.dispatchEvent( event );
  }

  _updateStore( block, hide ){
    const attr = block.dataset.toggle;
    const isLayer = attr && attr === 'layer';

    if( !hide ){
      this._expands.push( block );

      if( isLayer ){
        this._layers.push( block );
      }

      return;
    }

    const blockIndex = this._expands.indexOf( block );

    this._expands.splice( blockIndex, 1 );

    if( isLayer ){
      const layerIndex = this._layers.indexOf( block );

      this._layers.splice( layerIndex, 1 );
    }
  }
}

export default new ExpandToggle();
