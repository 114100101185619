class BubbleInfo {
  /**
   * @constructor
   */
  constructor() {
    this.els = document.querySelectorAll( '[data-bubbleinfo]' );
    this.addListener();
    this.isDocListen = false;
    this.bubContent = document.querySelectorAll( '.bubbleinfo-content' );
    this.thisRemoveDocListener = this.docListener.bind( this );
  }

  /**
   * add listener on all elements
   * @this BubbleInfo
   */
  addListener() {
    if ( this.els ) {
      for( let i = 0; i < this.els.length; i++ ) {
        this.els[ i ].addEventListener( 'click', this.theListener.bind( this ), false );
      }
    }
  }

  /**
   * the listener
   * @param { e } Event object
   */
  theListener( e ) {
    e.stopPropagation();

    let target = e.target;
    let dataType = target.getAttribute( 'data-bubbleinfo' );
    let span = document.createElement( 'span' );

    span.innerHTML = dataType;
    span.classList.add( 'bubbleinfo-content', 'isActive' );

    this.disable();

    // If the target does not contain .bubbleinfo-content element = Create it
    if( !target.contains( target.querySelector( '.bubbleinfo-content' ))) {
      // Stop if the target is bubbleinfo-content
      if( e.target.classList.contains( 'bubbleinfo-content' )) {
        return;
      }
      target.appendChild( span );
    }
    else {
      // Else : disable bubbleinfo-content
      e.target.firstElementChild.classList.toggle( 'isActive' );
    }

    this.bubContent = document.querySelectorAll( '.bubbleinfo-content' );

    if( this.isDocListen == false ) {
      this.addDocListener();
      this.isDocListen = true;
    }
  }

  /**
   * addDocListener
   * @description Add listener on document
   */
  addDocListener() {
    document.addEventListener( 'click', this.thisRemoveDocListener, false );
  }

  /**
   * docListener
   * @description Listner on document
   */
  docListener() {
    this.disable();
    document.removeEventListener( 'click', this.thisRemoveDocListener, false );
    this.isDocListen = false;
  }

  /**
   * disable
   * @description Disable active-tooltips
   */
  disable() {
    for( let i = 0; i < this.bubContent.length; i++ ) {
      this.bubContent[ i ].classList.remove( 'isActive' );
    }
  }
}

export default new BubbleInfo();
